// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    {
        theme: {
            themes: {
                custom: {
                    colors: {
                        primary: '#e4013a',
                        secondary: '#332e2e',
                    }
                }
            },
            defaultTheme: 'custom',
        }
    }
)
