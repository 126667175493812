import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_time_picker = _resolveComponent("v-time-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, {
    modelValue: _ctx.v,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v) = $event)),
    rules: _ctx.rules,
    label: _ctx.label,
    "prepend-icon": "mdi-clock-time-four-outline",
    color: "primary",
    readonly: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_menu, {
        modelValue: _ctx.showMenu,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showMenu) = $event)),
        "close-on-content-click": false,
        activator: "parent",
        transition: "scale-transition"
      }, {
        default: _withCtx(() => [
          (_ctx.showMenu)
            ? (_openBlock(), _createBlock(_component_v_time_picker, {
                key: 0,
                min: "08:00",
                max: "23:30",
                format: "24hr",
                modelValue: _ctx.v,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v) = $event)),
                "full-width": "",
                color: "primary"
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["modelValue", "rules", "label"]))
}