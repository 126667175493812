import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "text-caption",
  style: {"color":"red"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_slide_y_transition = _resolveComponent("v-slide-y-transition")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    ref: "form",
    modelValue: _ctx.isValid,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isValid) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        "model-value": _ctx.modelValue,
        persistent: _ctx.loading,
        "z-index": 5,
        scrollable: "",
        width: "600",
        "onUpdate:modelValue": _ctx.reset,
        fullscreen: _ctx.$vuetify.display.xs
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Anmelden ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.username,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                    color: "primary",
                    label: "Dein Name"
                  }, null, 8, ["modelValue"]),
                  (_ctx.event.transport_required)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("div", null, [
                          _createTextVNode(" Aktuelle Fahrzeugauslastung (mit dir): " + _toDisplayString(_ctx.participantCount) + "/" + _toDisplayString(_ctx.currentCarCapacity) + " ", 1),
                          _createVNode(_component_v_slide_y_transition, null, {
                            default: _withCtx(() => [
                              (!_ctx.hasSufficientCarCapacity)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Es fehlen Plätze für deine Anmeldung "))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _createVNode(_component_v_checkbox, {
                          modelValue: _ctx.hasCar,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hasCar) = $event)),
                          color: "primary",
                          label: "Ich habe ein Auto"
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_slide_y_transition, null, {
                          default: _withCtx(() => [
                            (_ctx.hasCar)
                              ? (_openBlock(), _createBlock(_component_v_text_field, {
                                  key: 0,
                                  modelValue: _ctx.carCapacity,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.carCapacity) = $event)),
                                  modelModifiers: { number: true },
                                  rules: [_ctx.RuleFactory.required()],
                                  color: "primary",
                                  label: "Plätze im Auto",
                                  type: "number"
                                }, null, 8, ["modelValue", "rules"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    disabled: _ctx.loading,
                    color: "secondary",
                    text: "Abbrechen",
                    variant: "text",
                    onClick: _ctx.reset
                  }, null, 8, ["disabled", "onClick"]),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    loading: _ctx.loading,
                    color: "primary",
                    disabled: !_ctx.hasSufficientCarCapacity,
                    text: "Speichern",
                    variant: "flat",
                    onClick: _ctx.preSave
                  }, null, 8, ["loading", "disabled", "onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model-value", "persistent", "onUpdate:modelValue", "fullscreen"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.showCookieDialog,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showCookieDialog) = $event)),
        width: "600"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Cookies für später ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Wir würden gerne einen Cookie mit einer ID speichern, um dich bei späteren Besuchen wiederzuerkennen. Damit wird es dir ermöglicht, deine Angaben im Nachhinein zu korrigieren oder dich wieder abzumelden. ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Diese Vorgänge sind ohne Speicherung des Cookies nicht möglich. ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    text: "",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => {_ctx.allow_cookies = false; _ctx.showCookieDialog = false; _ctx.save()})
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Speicherung ablehnen ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    text: "",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => {_ctx.allow_cookies = true; _ctx.showCookieDialog = false; _ctx.save()})
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Cookies erlauben ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}