<template>
  <v-form ref="form" v-model="isValid">
    <v-dialog :model-value="modelValue" :persistent="loading" :z-index="5" scrollable width="600"
              @update:model-value="reset" :fullscreen="$vuetify.display.xs">
      <v-card>
        <v-card-title>
          Neue Aktivität erstellen {{ isValid }}
        </v-card-title>
        <v-card-text class="pa-0">
<!--          <v-card-text>-->
<!--            <v-card class="mb-4" color="#f6f4f4" flat>-->
<!--              <v-card-title class="text-body-1 text-primary">-->
<!--                <v-icon class="mt-n2">mdi-lightbulb-on</v-icon>-->
<!--                Ideen-->
<!--              </v-card-title>-->
<!--            </v-card>-->
<!--          </v-card-text>-->
          <v-card-text>
            <v-text-field v-model="name" :rules="[RuleFactory.required()]" color="primary" label="Bezeichnung der Aktivität" required/>
            <v-textarea v-model="descr" color="primary"
                        label="Beschreibung (optional)"
                        rows="3"/>
            <v-list-subheader>Packliste (optional)</v-list-subheader>
            <v-list density="compact">
              <v-list-item v-for="(element, i) in packing_list" :key="i"
                           :title="element">
                <template v-slot:append>
                  <v-btn class="mt-2" color="primary" icon="mdi-delete" size="32" variant="text"
                         @click="removePackingListItem(i)"/>
                </template>
              </v-list-item>
            </v-list>
            <v-row>
              <v-col cols="10">
                <v-text-field v-model="new_packing_list_item" color="primary" label="Listenelement"
                              @keydown.enter="addPackingListItem"/>
              </v-col>
              <v-col cols="2">
                <v-btn :disabled="!new_packing_list_item" class="mt-2" color="primary" icon="mdi-plus" size="32"
                       variant="flat" @click="addPackingListItem"/>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider/>

          <v-card-text>
            <v-list-subheader>Zeitpunkt</v-list-subheader>
            <v-select v-model="event_day" :items="dayItems" color="primary" label="Tag" required/>
            <v-row>
              <v-col cols="6">
                <time-picker v-model="start_time" :rules="[RuleFactory.required()]" label="Beginn"/>
              </v-col>
              <v-col cols="6">
                <time-picker v-model="end_time" label="Ende (optional)"/>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider/>

          <v-card-text>
            <v-list-subheader>Ort</v-list-subheader>
            <v-combobox v-model="meeting_point" :items="meetingPointItems" :rules="[RuleFactory.required()]"
                        color="primary" label="Treffpunkt" required>
              <template v-slot:prepend-item>
                <div class="bg-grey-lighten-3 text-caption pl-2 py-2 mt-n2">
                  Vorschläge:
                </div>
              </template>
            </v-combobox>
            <v-checkbox v-model="hasDestination" color="primary" label="Aktivität auswärts?"/>
            <v-slide-y-transition>
              <div v-if="hasDestination" class="ml-4 mb-2">
                <v-text-field v-model="destination.name" color="primary" label="Bezeichnung des Orts"/>
                <v-text-field v-model="destination.street" color="primary" label="Straße, Hausnummer"/>
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="destination.zip" :rules="[RuleFactory.required()]" color="primary"
                                  label="PLZ"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="destination.city" :rules="[RuleFactory.required()]" color="primary"
                                  label="Ort"/>
                  </v-col>
                </v-row>
              </div>
            </v-slide-y-transition>
          </v-card-text>

          <v-divider/>

          <v-card-text>
            <v-list-subheader>Gruppengröße</v-list-subheader>
            <v-text-field v-model.number="initial_participants"
                          :rules="[RuleFactory.required(), RuleFactory.minValue(1), RuleFactory.maxValue(200), RuleFactory.isInteger()]"
                          color="primary" label="Aktuelle Gruppengröße"
                          min="1" required type="number"/>
            <v-text-field v-model.number="participant_limit" :rules="[RuleFactory.minValue(2), RuleFactory.maxValue(200), RuleFactory.isInteger()]"
                          color="primary" hint="Fahrzeugkapazität wird falls nötig separiert berechnet"
                          label="Maximale Teilnehmerzahl (optional)"
                          min="2" persistent-hint required type="number"/>
            <v-checkbox v-model="transport_required" color="primary" label="Fahrzeuge notwendig?"/>
            <v-slide-y-transition>
              <div v-if="transport_required" class="ml-4 mb-2">
                <v-list-subheader>
                  Vorhandende Fahrzeuge
                  <span v-if="cars.length > 0">
                (Gesamtkapazität: {{ cars.reduce((acc, car) => acc + car.capacity, 0) }})
              </span>
                  <span v-else>
                (optional)
              </span>
                </v-list-subheader>
                <v-list>
                  <v-list-item v-for="(car, i) in cars" :key="i" :subtitle="`Kapazität: ${car.capacity}`"
                               :title="`Fahrzeug ${i+1}`">
                    <template v-slot:append>
                      <v-btn class="mt-2" color="primary" icon="mdi-delete" size="32" variant="text"
                             @click="removeCar(i)"/>
                    </template>
                  </v-list-item>
                </v-list>
                <v-row>
                  <v-col cols="10">
                    <v-text-field v-model.number="new_car_capacity" :rules="[RuleFactory.minValue(1), RuleFactory.maxValue(20), RuleFactory.isInteger()]" color="primary"
                                  label="Fahrzeugkapazität" min="1" required type="number" @keydown.enter="addCar"/>
                  </v-col>
                  <v-col cols="2">
                    <v-btn :disabled="!new_car_capacity || new_car_capacity < 1" class="mt-2" color="primary"
                           icon="mdi-plus" size="32"
                           variant="flat" @click="addCar"/>
                  </v-col>
                </v-row>
              </div>
            </v-slide-y-transition>
          </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="loading" color="secondary" text="Abbrechen" variant="text" @click="reset"/>
          <v-spacer/>
          <v-btn :loading="loading" color="primary" text="Speichern" variant="flat" @click="preSave"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCookieDialog" width="600">
      <v-card>
        <v-card-title>
          Cookies für später
        </v-card-title>
        <v-card-text>
          Wir würden gerne einen Cookie mit einer ID speichern, um dich bei späteren Besuchen wiederzuerkennen.
          Damit wird es dir ermöglicht, die erstellte Aktivität im Nachhinein zu bearbeiten.
        </v-card-text>
        <v-card-text>
          Eine Bearbeitung im Nachhinein ist ohne die Speicherung des Cookies nicht möglich.
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="allow_cookies = false; showCookieDialog = false; save()">
            Speicherung ablehnen
          </v-btn>
          <v-btn color="primary" text @click="allow_cookies = true; showCookieDialog = false; save()">
            Cookies erlauben
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import dayjs from 'dayjs';
import {RuleFactory} from '@/ruleFactory.helper';
import {VForm} from 'vuetify/components/VForm';
import store from '@/store';
import axios from 'axios';
import TimePicker from '@/components/timePicker.vue';

export default defineComponent({
  components: {TimePicker},
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isValid: undefined,
    loading: false,

    name: '',
    descr: '',
    event_day: 0,
    start_time: undefined,
    end_time: undefined,
    new_packing_list_item: '',
    packing_list: [] as string[],
    meeting_point: undefined,

    hasDestination: false,
    destination: {
      name: '',
      street: '',
      zip: '',
      city: '',
    },

    initial_participants: 1,
    participant_limit: undefined,
    transport_required: false,

    cars: [] as { seats: number }[],

    new_car_capacity: undefined,

    meetingPointItems: ['Veranstaltungsscheune', 'Volleyballfeld', 'Feuerstelle', 'Vor dem Gutshaus'],

    showCookieDialog: false,
    allow_cookies: false,

    RuleFactory,
  }),
  computed: {
    dayItems() {
      const startDate = dayjs(`${process.env.VUE_APP_DATE_START}`);
      const endDate = dayjs(`${process.env.VUE_APP_DATE_END}`);
      const delta = endDate.diff(startDate, 'day') + 1;

      const items: Array<{ title: string; value: number }> = [];
      for (let i = 0; i < delta; i++) {
        let day = startDate.add(i, 'day');
        if (day.diff(dayjs(), 'day') < 0) {
          continue;
        }
        items.push({
          title: day.format('dddd, [den] DD.MM.YYYY'),
          value: i,
        });
      }
      return items;
    },
  },
  methods: {
    reset() {
      this.$emit('update:modelValue', false);
    },
    addCar() {
      if (this.new_car_capacity) {
        this.cars.push({seats: this.new_car_capacity});
        this.new_car_capacity = undefined;
      }
    },
    removeCar(i: number) {
      this.cars.splice(i, 1);
    },
    addPackingListItem() {
      if (this.new_packing_list_item) {
        this.packing_list.push(this.new_packing_list_item);
        this.new_packing_list_item = '';
      }
    },
    removePackingListItem(i: number) {
      this.packing_list.splice(i, 1);
    },
    async preSave() {
      const form = this.$refs.form as VForm | undefined;
      if (!form) {
        return;
      }
      const formState = await form.validate();
      if (!formState.valid) {
        return
      }
      if (store.state.uuid) {
        this.save();
      } else {
        this.showCookieDialog = true;
      }
    },
    async save() {
      const form = this.$refs.form as VForm | undefined;
      if (!form) {
        return;
      }
      const formState = await form.validate();
      if (!formState.valid) {
        return
      }
      this.loading = true;
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/events`, {
          name: this.name,
          descr: this.descr || undefined,
          event_day: this.event_day,
          start_time: dayjs(`${process.env.VUE_APP_DATE_START} ${this.start_time}`).add(this.event_day, 'day').toDate(),
          end_time: this.end_time && dayjs(`${process.env.VUE_APP_DATE_START} ${this.end_time}`).add(this.event_day, 'day').toDate(),
          packing_list: this.packing_list,
          meeting_point: this.meeting_point,
          destination: this.hasDestination ? this.destination : undefined,
          initial_participants: this.initial_participants,
          participant_limit: this.participant_limit || undefined,
          transport_required: this.transport_required,
          initial_cars: this.cars,
          allow_cookies: this.allow_cookies,
        }, {
          withCredentials: true,
        });
        this.reset();
        this.$emit('reload');
      } catch (e) {
        alert(e);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    const minValue = Math.min(...this.dayItems.map((el) => el.value));
    this.event_day = minValue;
  }
})
</script>
