import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.modelValue,
    persistent: _ctx.loading,
    "z-index": 5,
    scrollable: "",
    width: "600",
    "onUpdate:modelValue": _ctx.reset,
    fullscreen: _ctx.$vuetify.display.xs
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(" Absagen ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" Bist du sicher, dass du deine Teilnahme absagen möchtest? ")
            ]),
            _: 1
          }),
          (_ctx.participation?.car)
            ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(" Du hast ein Auto hinterlegt und ohne dieses könnte es für die Gruppe problematisch werden. Bitte überlege dir, ob du wirklich absagen möchtest. ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                disabled: _ctx.loading,
                color: "secondary",
                text: "Abbrechen",
                variant: "text",
                onClick: _ctx.reset
              }, null, 8, ["disabled", "onClick"]),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                loading: _ctx.loading,
                color: "primary",
                text: "Absagen",
                variant: "flat",
                onClick: _ctx.save
              }, null, 8, ["loading", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value", "persistent", "onUpdate:modelValue", "fullscreen"]))
}