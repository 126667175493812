<template>
  <v-text-field
      v-model="v"
      :rules="rules"
      :label="label"
      prepend-icon="mdi-clock-time-four-outline"
      color="primary"
      readonly
  >
    <v-menu
        v-model="showMenu"
        :close-on-content-click="false"
        activator="parent"
        transition="scale-transition"
    >
      <v-time-picker
          v-if="showMenu"
          min="08:00"
          max="23:30"
          format="24hr"
          v-model="v"
          full-width
          color="primary"
      ></v-time-picker>
    </v-menu>
  </v-text-field>
</template>

<script lang="ts">
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import {defineComponent} from 'vue'

export default defineComponent({
  components: {
    VTimePicker,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Uhrzeit',
    },
  },
  data: () => ({
    showMenu: false,
  }),
  computed: {
    v: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit('update:modelValue', value);
      },
    }
  },
})
</script>
