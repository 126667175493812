<template>
  <v-card :loading="loading">
    <v-card-title>
      <div>
        <div>
          {{event.name}}
        </div>
        <div class="text-body-1">
          {{day(event.start_time).format('DD.MM.YYYY HH:mm')}}
         <span v-if="event.end_time">
            -
          {{day(event.end_time).format('HH:mm')}}
         </span>
        </div>
      </div>
    </v-card-title>
    <v-card-text v-if="event.descr">
      {{event.descr}}
    </v-card-text>
    <v-list-item>
      <v-list-item-subtitle>
        Aktuelle Anzahl Teilnehmende:
      </v-list-item-subtitle>
      <v-list-item-title v-if="event.participant_limit">
        {{participantCount}}/{{event.participant_limit}}
      </v-list-item-title>
      <v-list-item-title v-else>
        {{participantCount}}
      </v-list-item-title>
    </v-list-item>
    <v-list-item>
      <v-list-item-subtitle>
        Treffpunkt:
      </v-list-item-subtitle>
      <v-list-item-title>
        {{event.meeting_point}}
      </v-list-item-title>
    </v-list-item>
    <v-list-item v-if="event.destination">
      <v-list-item-subtitle>
        Zielort:
      </v-list-item-subtitle>
      <v-list-item-title v-if="event.destination.name">
        {{event.destination.name}}
      </v-list-item-title>
      <v-list-item-title v-if="event.destination.street">
        {{event.destination.street}}
      </v-list-item-title>
      <v-list-item-title>
        {{event.destination.zip}} {{event.destination.city}}
      </v-list-item-title>
    </v-list-item>
    <v-card-text v-if="event.packing_list.length > 0">
      <div class="font-weight-bold">
        Was brauchst du?
      </div>
      <ul class="ml-4">
        <li v-for="item in event.packing_list" :key="item">
          {{item}}
        </li>
      </ul>
    </v-card-text>
    <div v-if="isCreator" class="text-center text-caption">
      Du hast diese Aktivität erstellt
    </div>
    <v-list>
      <v-list-item v-for="p in ownedParticipations" :key="p.uuid">
        <v-list-item-title>
          {{p.user.name || "Du"}}
        </v-list-item-title>
        <v-list-item-subtitle v-if="p.car">
          Fahrzeug mit {{p.car.seats}} {{p.car.seats === 1 ? 'Platz' : 'Plätzen'}}
        </v-list-item-subtitle>
        <template v-slot:append>
          <v-btn variant="text" color="primary" text="Absagen" @click="handleShowCancelParticipationDialog(p)"/>
        </template>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" :disabled="isCreator || isSignedUp" @click="showSignupDialog = true">
        Anmelden
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
  <signup-dialog :event="event" v-model="showSignupDialog" @reload="reloadEvent"/>
  <cancel-participation-dialog :participation="selectedParticipation" :event="event" v-model="showCancelParticipationDialog" @reload="reloadEvent"/>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {DaysEvent} from '@/serverData';
import dayjs from 'dayjs';
import store from '@/store';
import SignupDialog from '@/components/SignupDialog.vue';
import CancelParticipationDialog from '@/components/CancelParticipationDialog.vue';
import axios from 'axios';

export default defineComponent({
  components: {CancelParticipationDialog, SignupDialog},
  props: {
    event: {
      type: Object as () => DaysEvent,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    day: dayjs,
    showSignupDialog: false,
    selectedParticipation: undefined as unknown as DaysEvent['additional_participants'][0],
    showCancelParticipationDialog: false,
  }),
  computed: {
    ownedParticipations(): DaysEvent['additional_participants'] {
      if (!store.state.uuid) {
        return [];
      }
      return this.event.additional_participants?.filter((el) => el.user.uuid === store.state.uuid) || [];
    },
    participantCount(): number {
      return this.event.initial_participants + (this.event.additional_participants?.length || 0);
    },
    isCreator(): boolean {
      if (this.event.creator?.uuid && store.state.uuid) {
        return this.event.creator.uuid === store.state.uuid;
      } else {
        return false;
      }
    },
    isSignedUp(): boolean {
      if (store.state.uuid) {
        return !!this.event.additional_participants?.find((el) => el.user.uuid === store.state.uuid)
      }
      return false;
    },
  },
  methods: {
    handleShowCancelParticipationDialog(participation: DaysEvent['additional_participants'][0]) {
      this.selectedParticipation = participation;
      this.showCancelParticipationDialog = true;
    },
    async reloadEvent() {
      this.loading = true;
      try {
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/events/id/${this.event.uuid}`, {
          withCredentials: true,
        });
        this.$emit('update:event', resp.data.Ok);
      } catch (e) {
        store.commit('handleError', e);
      } finally {
        this.loading = false;
      }
    },
  },
})
</script>
