import {createStore} from 'vuex'
import {AxiosError} from 'axios';

export default createStore({
    state: {
        uuid: null as string | null,
        snackbarText: undefined as string | undefined,
        showSnackbar: false,
        snackbarHasError: false,
    },
    getters: {},
    mutations: {
        setAuth(state, auth: string | null) {
            state.uuid = auth;
        },
        handleSuccess(state, message: string) {
            state.snackbarHasError = false;
            state.snackbarText = message;
            state.showSnackbar = true;
            setTimeout(() => {
                state.showSnackbar = false;
            }, 5000);
        },
        handleError(state, error: any) {
            state.snackbarHasError = true;
            const axiosError = error as AxiosError;
            const errorMessage = (axiosError?.response?.data as any)?.Err || axiosError?.message;
            if (errorMessage) {
                state.snackbarText = errorMessage;
            } else {
                state.snackbarText = 'Ein unbekannter Fehler ist aufgetreten';
            }
            state.showSnackbar = true;
            setTimeout(() => {
                state.showSnackbar = false;
            }, 5000);
        },
    },
    actions: {},
    modules: {},
})
