<template>
  <v-app>
    <v-main style="z-index: 1">
      <v-toolbar>
        <v-toolbar-items>
          <v-btn href="https://www.cvjmbaden.de" target="_blank">
            <img alt="CVJM Landesverband Baden" height="48" src="logos/CVJM_Baden.png"/>
          </v-btn>
        </v-toolbar-items>
        <v-spacer/>
        <div class="font-weight-black text-uppercase text-caption">
          Es-ist-Days-und-mir-ist-langweilig.de
        </div>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn href="https://www.cvjmbaden.de/website/de/cb/erleben/jahresprogramm/2024/days" target="_blank">
            <img alt="Days - Tage für Junge Erwachsende" height="48" src="logos/Days.png"/>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12" v-if="initializing">
            <div class="text-center">
              Lade Aktivitäten...
            </div>
            <v-progress-linear color="primary" indeterminate/>
          </v-col>
          <v-col cols="12" v-if="!initializing && (!events || events.length === 0)">
            <v-alert color="info">
              Aktuell gibt es keine zukünftigen Aktivitäten. Lege gerne selber eine an <v-icon size="24">mdi-emoticon</v-icon>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6" v-for="(event, i) in events" :key="event.uuid">
            <event-card v-model:event="events[i]"/>
          </v-col>
        </v-row>
      </v-container>
<!--      <div class="text-caption">-->
<!--        {{uuid}}-->
<!--      </div>-->
      <v-btn color="primary" icon="mdi-plus" location="bottom right" class="ma-4" position="fixed" @click="showCreateEventDialog = true"/>
      <create-event-dialog v-model="showCreateEventDialog" @reload="initialize"/>
      <v-snackbar v-model="showSnackbar">
        <v-icon :color="snackbarHasError ? 'error' : 'success'">
          {{snackbarHasError ? 'mdi-alert-circle' : 'mdi-check-circle'}}
        </v-icon>
        {{snackbarText}}
      </v-snackbar>
    </v-main>
    <div style="width: 100%; max-width: 300px; z-index: 0; filter: opacity(0.1) saturate(0.1); height: 200px; position: fixed; bottom: 0; right: 0; background-image: url('cvjm_bg.png'); background-size: cover" />
  </v-app>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {DaysEvent} from '@/serverData';
import EventCard from '@/components/EventCard.vue';
import store from '@/store';
import CreateEventDialog from '@/components/CreateEventDialog.vue';
import axios from 'axios';

export default defineComponent({
  name: 'App',

  components: {CreateEventDialog, EventCard},

  data: () => ({
    showCreateEventDialog: false,
    events: [] as DaysEvent[],
    initializing: false,
  }),
  computed: {
    uuid() {
      return store.state.uuid;
    },
    snackbarText(): string | undefined {
      return store.state.snackbarText;
    },
    showSnackbar(): boolean {
      return store.state.showSnackbar;
    },
    snackbarHasError(): boolean {
      return store.state.snackbarHasError;
    },
  },
  methods: {
    async get_auth() {
      const resp = await axios.get(`${process.env.VUE_APP_API_URL}/auth/uuid`, {
        withCredentials: true,
      });
      const auth_details: { has_auth: boolean, uuid: string | null } = await resp.data;
      if (auth_details.has_auth) {
        store.commit('setAuth', auth_details.uuid);
      }
    },

    async initialize() {
      this.initializing = true;
      try {
        await this.get_auth();
        const resp = await axios.get(`${process.env.VUE_APP_API_URL}/events/upcoming`, {
          withCredentials: true,
        });
        this.events = await resp.data;
      } catch (e) {
        store.commit('handleError', e);
      } finally {
        this.initializing = false;
      }
    },
  },
  mounted() {
    this.initialize()
  },
})
</script>

