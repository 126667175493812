export type Rule = (value: any) => string | boolean;

function isEmpty(value: any): boolean {
    return typeof value === 'undefined' || value === null || value === '';
}

const required = (msg?: string): Rule => {
    return (v: any) => {
        if (Array.isArray(v)) {
            return v.length > 0 || msg || 'Dieses Feld ist erforderlich';
        }
        return !isEmpty(v) || msg || 'Dieses Feld ist erforderlich';
    };
};

const maxLength = (length: number, msg?: string): Rule => {
    return (v: string) => {
        return (!v || v.length <= length)
            || msg || `Die maximale Zeichenlänge beträgt ${length} Zeichen`;
    };
};

const minLength = (length: number, msg?: string): Rule => {
    return (v: string) => {
        return (!v || v.length >= length)
            || msg || `Die minimale Zeichenlänge beträgt ${length} Zeichen`;
    };
};

const maxValue = (max: number, msg?: string): Rule => {
    return (v: string | number) => {
        return (isEmpty(v) || Number(v) <= max)
            || msg || `Der Maximalwert für dieses Feld liegt bei ${max}`;
    };
};

const minValue = (min: number, msg?: string): Rule => {
    return (v: string | number) => {
        return (isEmpty(v) || Number(v) >= min)
            || msg || `Der Minimalwert für dieses Feld liegt bei ${min}`;
    };
};

const isInteger = (msg?: string): Rule => {
    return (v: number) => {
        const num = Number(v);
        const valid = !isNaN(num) && num - Math.round(num) === 0;
        return !v || valid || msg || 'Für dieses Feld sind nur Ganzzahlen zulässig';
    };
};

const isNumber = (msg?: string): Rule => {
    return (v: any) => {
        return isEmpty(v) || typeof v === 'number' && !isNaN(v) || msg || 'Für dieses Feld sind nur Zahlen zulässig';
    };
};

const isNumericString = (msg?: string): Rule => {
    return (v: any) => {
        const num = Number(v);
        return isEmpty(v) || !isNaN(num) || msg || 'Für dieses Feld sind nur Ziffern zulässig';
    };
};

const isValidMail = (msg?: string): Rule => {
    return (v: string) => {
        if (!v) {
            return true;
        }

        const values: any[] = [];

        if (Array.isArray(v)) {
            if (v.length === 0) {
                return true;
            }
            values.push(...v);
        } else {
            values.push(v);
        }

        for (const value of values) {
            // eslint-disable-next-line no-control-regex
            const rex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gmi;
            const result = rex.exec(value);
            if (!result || result[0].length !== value.length) {
                return msg || Array.isArray(v) ? `"${value}" keine gültige E-Mail-Adresse` : 'Das ist keine gültige E-Mail-Adresse';
            }
        }
        return true;
    };
};

const is = (value: any, msg: string): Rule => {
    return (v: any) => {
        return v === value || msg;
    };
}

const minArrLength = (value: number, msg?: string): Rule => {
    return (v: any[]) => {
        return !v || (Array.isArray(v) && value <= v.length) || msg || `Bitte mindestens ${value === 1 ? `${value} Element` : `${value} Elemente`} auswählen`;
    }
}

const maxArrLength = (value: number, msg?: string): Rule => {
    return (v: any[]) => {
        return !v || (Array.isArray(v) && value >= v.length) || msg || `Bitte maximal ${value === 1 ? `${value} Element` : `${value} Elemente`} auswählen`;
    }
}

const isIn = (params: Array<string | number>, msg: string): Rule => {
    return (v: string) => {
        return !v || params.map((param) => param.toString()).includes(v) || msg;
    };
};

const isNotIn = (params: Array<string | number>, msg: string): Rule => {
    return (v: string) => {
        return !v || !params.map((param) => param.toString()).includes(v) || msg;
    };
};

const includes = (rex: RegExp, msg: string): Rule => {
    return (v: string) => {
        return !v || rex.test(v) || msg;
    };
};

const includesNot = (rex: RegExp, msg: string): Rule => {
    return (v: string) => {
        return !v || !rex.test(v) || msg;
    };
};

/** Bundle of different rules for vuetify */
export const RuleFactory = {
    required,
    maxLength,
    minLength,
    maxValue,
    minValue,
    isInteger,
    isNumber,
    isNumericString,
    isValidMail,
    is,
    isIn,
    isNotIn,
    includes,
    includesNot,
    minArrLength,
    maxArrLength,
};
