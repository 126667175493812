<template>
  <v-form ref="form" v-model="isValid">
    <v-dialog :model-value="modelValue" :persistent="loading" :z-index="5" scrollable width="600"
              @update:model-value="reset" :fullscreen="$vuetify.display.xs">
      <v-card>
        <v-card-title>
          Anmelden
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="username" color="primary" label="Dein Name"/>

          <div v-if="event.transport_required">
            <div>
              Aktuelle Fahrzeugauslastung (mit dir):
              {{ participantCount }}/{{ currentCarCapacity }}
              <v-slide-y-transition>
                <div class="text-caption" style="color: red" v-if="!hasSufficientCarCapacity">
                  Es fehlen Plätze für deine Anmeldung
                </div>
              </v-slide-y-transition>
            </div>


            <v-checkbox v-model="hasCar" color="primary" label="Ich habe ein Auto"/>
            <v-slide-y-transition>
              <v-text-field v-if="hasCar" v-model.number="carCapacity" :rules="[RuleFactory.required()]" color="primary"
                            label="Plätze im Auto" type="number"/>
            </v-slide-y-transition>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="loading" color="secondary" text="Abbrechen" variant="text" @click="reset"/>
          <v-spacer/>
          <v-btn :loading="loading" color="primary" :disabled="!hasSufficientCarCapacity" text="Speichern" variant="flat" @click="preSave"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCookieDialog" width="600">
      <v-card>
        <v-card-title>
          Cookies für später
        </v-card-title>
        <v-card-text>
          Wir würden gerne einen Cookie mit einer ID speichern, um dich bei späteren Besuchen wiederzuerkennen.
          Damit wird es dir ermöglicht, deine Angaben im Nachhinein zu korrigieren oder dich wieder abzumelden.
        </v-card-text>
        <v-card-text>
          Diese Vorgänge sind ohne Speicherung des Cookies nicht möglich.
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="allow_cookies = false; showCookieDialog = false; save()">
            Speicherung ablehnen
          </v-btn>
          <v-btn color="primary" text @click="allow_cookies = true; showCookieDialog = false; save()">
            Cookies erlauben
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import dayjs from 'dayjs';
import {RuleFactory} from '@/ruleFactory.helper';
import {VForm} from 'vuetify/components/VForm';
import store from '@/store';
import axios from 'axios';
import {DaysEvent} from '@/serverData';

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object as () => DaysEvent,
      required: true,
    },
  },
  data: () => ({
    isValid: undefined,
    loading: false,

    username: '',
    hasCar: false,
    carCapacity: undefined as number | undefined,

    showCookieDialog: false,
    allow_cookies: false,

    RuleFactory,
  }),
  computed: {
    participantCount(): number {
      return this.event.initial_participants + (this.event.additional_participants?.length || 0) + 1;
    },
    currentCarCapacity(): number {
      let capacity = this.event.initial_cars?.map(c => c.seats).reduce((a, b) => a + b, 0) || 0;
      if (this.event.additional_participants) {
        for (const participant of this.event.additional_participants) {
          if (participant.car) {
            capacity += participant.car.seats;
          }
        }
      }
      return capacity;
    },
    hasSufficientCarCapacity() {
      if (!this.event.transport_required) {
        return true;
      }
      return this.participantCount <= this.currentCarCapacity + (this.carCapacity || 0);
    },
    dayItems() {
      const startDate = dayjs(`${process.env.VUE_APP_DATE_START}`);
      const endDate = dayjs(`${process.env.VUE_APP_DATE_END}`);
      const delta = endDate.diff(startDate, 'day') + 1;

      const items: Array<{ title: string; value: number }> = [];
      for (let i = 0; i < delta; i++) {
        let day = startDate.add(i, 'day');
        if (day.diff(dayjs(), 'day') < 0) {
          continue;
        }
        items.push({
          title: day.format('dddd, [den] DD.MM.YYYY'),
          value: i,
        });
      }
      return items;
    },
  },
  methods: {
    reset() {
      this.$emit('update:modelValue', false);
    },
    async preSave() {
      const form = this.$refs.form as VForm | undefined;
      if (!form) {
        return;
      }
      const formState = await form.validate();
      if (!formState.valid) {
        return
      }
      if (store.state.uuid) {
        this.save();
      } else {
        this.showCookieDialog = true;
      }
    },
    async save() {
      const form = this.$refs.form as VForm | undefined;
      if (!form) {
        return;
      }
      const formState = await form.validate();
      if (!formState.valid) {
        return
      }
      this.loading = true;
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/participations`, {
          event_uuid: this.event.uuid,
          username: this.username || undefined,
          car: this.hasCar ? {seats: this.carCapacity} : undefined,
          allow_cookies: this.allow_cookies,
        }, {
          withCredentials: true,
        });
        store.commit('handleSuccess', 'Du hast dich erfolgreich angemeldet');
        this.reset();
        this.$emit('reload');
      } catch (e) {
        store.commit('handleError', e);
      } finally {
        this.loading = false;
      }
    },
  },
})
</script>
