import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import dayjs from 'dayjs';
require('dayjs/locale/de');

import updateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(updateLocale);

dayjs.updateLocale('de', {
    weekdays: [
        "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"
    ]
});

dayjs.locale('de');

loadFonts()

createApp(App)
  .use(store)
  .use(vuetify)
  .mount('#app')
