<template>
  <v-dialog :model-value="modelValue" :persistent="loading" :z-index="5" scrollable width="600"
            @update:model-value="reset" :fullscreen="$vuetify.display.xs">
    <v-card>
      <v-card-title>
        Absagen
      </v-card-title>
      <v-card-text>
        Bist du sicher, dass du deine Teilnahme absagen möchtest?
      </v-card-text>
      <v-card-text v-if="participation?.car">
        Du hast ein Auto hinterlegt und ohne dieses könnte es für die Gruppe problematisch werden.
        Bitte überlege dir, ob du wirklich absagen möchtest.
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" color="secondary" text="Abbrechen" variant="text" @click="reset"/>
        <v-spacer/>
        <v-btn :loading="loading" color="primary" text="Absagen" variant="flat" @click="save"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import dayjs from 'dayjs';
import {RuleFactory} from '@/ruleFactory.helper';
import {VForm} from 'vuetify/components/VForm';
import store from '@/store';
import axios from 'axios';
import {DaysEvent} from '@/serverData';
import {tr} from 'vuetify/locale';

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object as () => DaysEvent,
      required: true,
    },
    participation: {
      type: Object as () => DaysEvent['additional_participants'][0],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    RuleFactory,
  }),
  methods: {
    reset() {
      this.$emit('update:modelValue', false);
    },
    async save() {
      this.loading = true;
      try {
        await axios.delete(`${process.env.VUE_APP_API_URL}/participations`,{
          withCredentials: true,
          data: {
            event_uuid: this.event.uuid,
            participation_uuid: this.participation.uuid,
          },
        });
        store.commit('handleSuccess', 'Du hast dich erfolgreich abgemeldet');
        this.reset();
        this.$emit('reload');
      } catch (e) {
        store.commit('handleError', e);
      } finally {
        this.loading = false;
      }
    },
  },
})
</script>
